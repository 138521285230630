import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Carousel from 'nuka-carousel'

// IMPROVEMENT :try externalize the gallery generation
// import galleryGenerate from '../components/galleryPictures'

import Layout from '../components/layout'
import Head from '../components/head'

// import { query } from '../constants/locationQuery'

import {
  document,
  galleryItemArray,
  dateStart,
  dateEnd,
  fares,
  statuses,
  periods,
  titleDescr,
  descr,
} from '../constants/locationTemplate'

import { houseIconAlt, bigSwimmingPoolIconAlt } from '../constants/iconAlt'

import bodyStyles from '../sass/components/body.module.scss'
import locationStyles from '../sass/pages/location.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import buttonStyles from '../sass/components/button.module.scss'
import carouselGalleryStyles from '../sass/components/carouselGalleryBoxes.module.scss'
// import homeStyles from '../sass/components/common.module.scss'

import houseIconSvg from '../assets/icons/vcd-house-color.svg'
import bigSwimmingPoolIconSvg from '../assets/icons/vcd-big-swimming-pool-color.svg'

const bS = bodyStyles
const btS = buttonStyles
const cgS = carouselGalleryStyles
const lS = locationStyles
const cmS = commonStyles

// let cl = (...arg) => console.log(...arg)

// %%% For wpgraphql-powered WP site %%%%%%%%%%%%%%%%%%%%%%%%

// IMPROVEMENT : try externalize the graphql query
// export const query = graphql`${queryLocation}` // Doesnt work
// NO, put it at the end of the file ==> This line reminds the graphql static? query is run from an external file

const Location = props => {
  const dataShort = props.data.wpgraphql.locationBy
  const data = props.data.wpgraphql.locationBy.locationAcf

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        // "embedded-asset-block": node => { // doesn't work !  (try -entry- ?)
        // What is node and why it doesnt pas information ? Probably linked to another component => not fully understood !
        const alt = data.picture.altText
        const imageurl = data.picture.sourceUrl
        return <img className={cgS.boxMainImage} alt={alt} src={imageurl} />
      },
    },
  }

  // IMPROVEMENT : try externalize the gallery generation
  // const galleryOptions = galleryGenerate(imgStyle)

  let galleryOptions = []

  galleryItemArray.forEach((galleryItem, index) => {
    galleryOptions[index] = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: () => {
          const picture = data[galleryItem] ? data[galleryItem] : null
          if (data[galleryItem]) {
            const alt = picture.altText
            const imageurl = picture.sourceUrl
            return <img className={cgS.boxOption} alt={alt} src={imageurl} />
          }
        },
      },
    }
  })

  return (
    <Layout>
      <Head title={dataShort.title} />

      <div className={bS.container}>
        <h1 className={cmS.title}> Votre location </h1>
        <div className={cmS.iconWrapperWrapper}>
          <div className={cmS.iconWrapper}>
            <img
              className={cmS.box3Icon}
              src={bigSwimmingPoolIconSvg}
              alt={bigSwimmingPoolIconAlt}
            />
            <img
              className={cmS.box2Icon}
              src={houseIconSvg}
              alt={houseIconAlt}
            />
          </div>
        </div>

        {/* Section 1 : Title and main image */}
        <div className={cmS.mainComponentWrapper}>
          <div className={cmS.titleWrapper}>
            <h1 className={lS.titleLocation}> {dataShort.title} </h1>
          </div>

          <div className={cgS.carouselVisible}>
            <div className={cmS.mainImageWrapper}>
              <div className={cmS.mainImage}>
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      className={btS.buttonControlLeftCarousel}
                    >
                      {'<<'}
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      className={btS.buttonControlRightCarousel}
                    >
                      {'>>'}
                    </button>
                  )}
                  renderBottomCenterControls={({ currentSlide }) => null}
                  // withoutControls
                  autoplay={true}
                  // autoplayReverse={true}
                  pauseOnHover={false}
                  // slideIndex={7}
                  slidesToShow={1}
                  // autoGenerateStyleTag={false}
                  transitionMode={'scroll'}
                  width="100%"
                  wrapAround={true}
                >
                  {documentToReactComponents(document, options)}
                  {galleryOptions.map(galleryOption =>
                    documentToReactComponents(document, galleryOption)
                  )}
                </Carousel>
              </div>
            </div>
          </div>

          <div className={cgS.mainImageVisible}>
            <div className={cmS.mainImageWrapper}>
              <div className={cmS.mainImage}>
                {documentToReactComponents(document, options)}
              </div>
            </div>
            <div className={cmS.mainImageWrapper}>
              <div className={cmS.mainImage}>
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      className={btS.buttonControlLeftCarousel}
                    >
                      {'<<'}
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      className={btS.buttonControlRightCarousel}
                    >
                      {'>>'}
                    </button>
                  )}
                  renderBottomCenterControls={({ currentSlide }) => null}
                  // withoutControls
                  autoplay={true}
                  // autoplayReverse={true}
                  pauseOnHover={false}
                  // slideIndex={7}
                  slidesToShow={2}
                  // autoGenerateStyleTag={false}
                  transitionMode={'scroll'}
                  width="100%"
                  wrapAround={true}
                >                  
                  {galleryOptions.map(galleryOption =>
                    documentToReactComponents(document, galleryOption)
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        {/* --------------
        <div className={cmS.mainComponentWrapper}>
          <div className={cmS.titleWrapper}>
            <h1 className={lS.titleLocation}> {dataShort.title} </h1>
          </div>
          <div className={cmS.mainImageWrapper}>
            <div className={cmS.mainImage}>
              {documentToReactComponents(document, options)}
            </div>
          </div>
        </div>
-------------- */}

        {/* Section 2, Fares : title and 1 box / period (Bef,Jun, Jul, Aug, Sep, Aft) */}
        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}> {data.titleFares} </h2>
          <div className={cmS.paragraphWrapper}>
            <div className={lS.paragraphBox}>
              <p className={cmS.paragraph}>{data.faresDescription}</p>
            </div>
          </div>

          {/* Repeating part */}
          <div className={lS.paragraphWrapperPeriod}>
            {/* Looping on the period */}
            {periods.map((period, indexP) => (
              <div className={lS.paragraphBoxWrapperPeriod}>
                <div className={lS.paragraphBox}>
                  <div className={cmS.titleParagraphWrapperWrapper}>
                    <div className={cmS.titleParagraphWrapper}>
                      <p className={lS.titleParagraph}>{period}</p>
                    </div>
                  </div>

                  <div className={lS.periodListWrapper}>
                    {dateStart[indexP].map((dataStartItem, indexS) =>
                      data[dataStartItem] ? (
                        <div className={lS.periodList}>
                          <div
                            className={
                              data[statuses[indexP][indexS]] === 'Disponible'
                                ? lS.periodBoxWrapperOrange
                                : lS.periodBoxWrapperLavander
                            }
                          >
                            <div className={lS.periodBox}>
                              <p className={lS.textPeriodBox}>
                                Du {data[dateStart[indexP][indexS]]}
                              </p>
                            </div>
                            <div className={lS.periodBox}>
                              <p className={lS.textPeriodBox}>
                                au {data[dateEnd[indexP][indexS]]} :
                              </p>
                            </div>
                          </div>

                          <div
                            className={
                              data[statuses[indexP][indexS]] === 'Disponible'
                                ? lS.periodBoxWrapperOrange
                                : lS.periodBoxWrapperLavander
                            }
                          >
                            <div className={lS.periodBox}>
                              <p className={lS.textPeriodBox}>
                                {data[statuses[indexP][indexS]]}
                              </p>
                            </div>
                            <div className={lS.periodBox}>
                              <p className={lS.textPeriodBox}>
                                {data[fares[indexP][indexS]] + ' €/sem'}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Section 3, Description : title and 4 boxes */}
        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}> {data.titleDescription} </h2>

          <div className={lS.paragraphWrapper}>
            {descr.map((descr, index) =>
              descr ? (
                <div className={lS.paragraphBoxWrapper}>
                  <div className={lS.paragraphBox}>
                    <div className={cmS.titleParagraphWrapperWrapper}>
                      <div className={cmS.titleParagraphWrapper}>
                        <p className={lS.titleParagraph}>
                          {data[titleDescr[index]]}
                        </p>
                      </div>
                    </div>
                    <p className={cmS.paragraph}>{data[descr]} </p>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>

        {/* Section 4, Equipements */}
        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}>Equipements et agencement</h2>

          <div className={lS.paragraphWrapper}>
            {/*  
              {descr.map( (descr,index) =>
             descr ? ( 
               */}
            <div className={lS.paragraphBoxWrapper}>
              <div className={lS.paragraphBox}>
                <div className={cmS.titleParagraphWrapperWrapper}>
                  <div className={cmS.titleParagraphWrapper}>
                    <p className={lS.titleParagraph}>
                      titre équipement
                      {/*
                        {data[titleDescr[index]]} 
                    */}
                    </p>
                  </div>
                </div>
                <p className={cmS.paragraph}>
                  A venir très bientôt!
                  {/*
                   {data[descr]}
                 */}
                </p>
              </div>
            </div>
            {/*
               ) : null
             )}
              */}
          </div>
        </div>

        {/* Section 5, Galerie photos */}
        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}> Galerie photos </h2>
          <div className={lS.galleryContainer}>
            <div className={cmS.gridContainer}>
              {galleryOptions.map((galleryOption, index) => (
                <div className={eval(`lS.box${index + 1}`)}>
                  {documentToReactComponents(document, galleryOption)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Location

export const query = graphql`
  query($locationId: Int!) {
    wpgraphql {
      locationBy(locationId: $locationId) {
        slug
        title
        locationAcf {
          picture {
            sourceUrl
            altText
            title
          }
          titleDescription
          titleDescriptionP1
          titleDescriptionP2
          titleDescriptionP3
          titleDescriptionP4
          descriptionP1
          descriptionP2
          descriptionP3
          descriptionP4
          titleFares
          faresDescription

          dateStart1Jun
          dateStart2Jun
          dateStart3Jun
          dateStart4Jun
          dateStart1Jul
          dateStart2Jul
          dateStart3Jul
          dateStart4Jul
          dateStart1Aug
          dateStart2Aug
          dateStart3Aug
          dateStart4Aug
          dateStart1Sep
          dateStart2Sep
          dateStart3Sep
          dateStart4Sep

          dateEnd1Jun
          dateEnd2Jun
          dateEnd3Jun
          dateEnd4Jun
          dateEnd1Jul
          dateEnd2Jul
          dateEnd3Jul
          dateEnd4Jul
          dateEnd1Aug
          dateEnd2Aug
          dateEnd3Aug
          dateEnd4Aug
          dateEnd1Sep
          dateEnd2Sep
          dateEnd3Sep
          dateEnd4Sep

          fare1Jun
          fare2Jun
          fare3Jun
          fare4Jun
          fare1Jul
          fare2Jul
          fare3Jul
          fare4Jul
          fare1Aug
          fare2Aug
          fare3Aug
          fare4Aug
          fare1Sep
          fare2Sep
          fare3Sep
          fare4Sep

          status1Jun
          status2Jun
          status3Jun
          status4Jun
          status1Jul
          status2Jul
          status3Jul
          status4Jul
          status1Aug
          status2Aug
          status3Aug
          status4Aug
          status1Sep
          status2Sep
          status3Sep
          status4Sep

          galleryPicture01 {
            sourceUrl
            altText
            title
          }
          galleryPicture02 {
            sourceUrl
            altText
            title
          }
          galleryPicture03 {
            sourceUrl
            altText
            title
          }
          galleryPicture04 {
            sourceUrl
            altText
            title
          }
          galleryPicture05 {
            sourceUrl
            altText
            title
          }
          galleryPicture06 {
            sourceUrl
            altText
            title
          }
          galleryPicture07 {
            sourceUrl
            altText
            title
          }
          galleryPicture08 {
            sourceUrl
            altText
            title
          }
          galleryPicture09 {
            sourceUrl
            altText
            title
          }
          galleryPicture10 {
            sourceUrl
            altText
          }
          galleryPicture11 {
            sourceUrl
            altText
          }
          galleryPicture12 {
            sourceUrl
            altText
          }
          galleryPicture13 {
            sourceUrl
            altText
          }
          galleryPicture14 {
            sourceUrl
            altText
          }
          galleryPicture15 {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`
